import React, { useContext, useEffect, useState } from "react";
import { graphql } from "@apollo/client/react/hoc";
import styled from "styled-components";
import { RootContext } from "@common/admin/state/root_store";

import { LEADS_QUERY } from "@common/admin/gql/queries/leads.gql";

const Leads = ({ data }) => {
	const [leads, setLeads] = useState([]);
	const RootStore = useContext(RootContext);

	useEffect(() => {
		if (data.leads && data.leads.length && !leads.length) {
			setLeads(data.leads);
		}
	}, [data]);

	useEffect(() => {
		RootStore.deepstream.event.subscribe("leads/new", lead => {
			const idx = leads.findIndex(l => l.id === lead.id);
			if (idx == -1) {
				const newLeads = [...leads];
				newLeads.unshift(lead);

				setLeads(newLeads);
			} else {
				console.log("DOES NOT EXIST");
			}
		});
		return () => {
			RootStore.deepstream.event.unsubscribe("leads/new");
		};
	}, [leads]);

	return (
		<Container>
			<Inner>
				<h1>Interessenter</h1>
				<ul>
					{leads.length
						? leads.map(l => (
								<li key={l.id}>
									<span>{l.first_name}</span>
									<span>{l.last_name}</span>
									<span>{l.email}</span>
									<span>{l.zip}</span>
									<span>{l.created_at}</span>
									<span>{l.updated_at}</span>
								</li>
						  ))
						: null}
				</ul>
			</Inner>
		</Container>
	);
};

const Container = styled.div`
	display: block;
	width: 100%;
	max-height: calc(100vh - 60px);
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
`;

const Inner = styled.div`
	display: flex;
	flex-flow: column;
	max-width: 1140px;
	margin: 0 auto;
	padding: 60px 18px;

	ul {
		display: flex;
		flex-flow: column;
		width: 100%;

		li {
			display: flex;
			flex-flow: row nowrap;
			width: 100%;

			span {
				flex: 1;
			}
		}
	}
`;

export default graphql(LEADS_QUERY)(Leads);
